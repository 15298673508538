import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"

// import MediaContentOne from "./assets/MediaContentOne.jpg"
import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentOneM from "./assets/MediaContentOneM.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
// import MediaContentFive from "./assets/MediaContentFive.jpg"
import MediaContentFive from "./assets/MediaContentFive.webp"
// import MediaContentSix from "./assets/MediaContentSix.jpg"
import MediaContentSix from "./assets/MediaContentSix.webp"
// import MediaContentSeven from "./assets/MediaContentSeven.jpg"
import MediaContentSeven from "./assets/MediaContentSeven.webp"
// import MediaContentEight from "./assets/MediaContentEight.jpg"
import MediaContentEight from "./assets/MediaContentEight.webp"
// import MediaContentNine from "./assets/MediaContentNine.jpg"
import MediaContentNine from "./assets/MediaContentNine.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading: "Главное — понимать, для кого и зачем открываешь заведение",
		textarea: (
			<>
				Я много лет катаюсь на велосипеде&nbsp;&mdash; это одно из моих главных
				увлечений. К сожалению, в&nbsp;2018 году велосипедная тусовка
				в&nbsp;Питере начала затухать. Все проводили время в&nbsp;своих районах
				и&nbsp;друг друга особо не знали. Чтобы это исправить, мы
				с&nbsp;друзьями начали организовывать покатушки по средам,
				на&nbsp;которые мог прийти любой желающий. Назвали «Среда обитания».
				Мероприятие было очень популярное. Мы встречались с&nbsp;ребятами около
				какого-нибудь кафе и&nbsp;все вместе ехали кататься.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={styles.icon__one}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>
					В 2020 году я&nbsp;решился на&nbsp;бизнес с 200 тысячами рублей
					в&nbsp;кармане
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentOne)}>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<picture className="w-full">
							<source srcSet={MediaContentOne} media="(min-width: 395px)" />
							<img
								className={styles.mediaContent__coverImg}
								src={MediaContentOneM}
								alt="владелец кофейни Максим Кравцов"
								loading="lazy"
							/>
						</picture>
						{/*<img*/}
						{/*	className={styles.mediaContent__coverImg}*/}
						{/*	src={MediaContentOne}*/}
						{/*	alt="владелец кофейни Максим Кравцов"*/}
						{/*	loading="lazy"*/}
						{/*/>*/}
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					Чтобы велосипедная тусовка <br className={styles.contentSmallShow} />{" "}
					в&nbsp;Питере не затухла, <br className={styles.contentSmallShow} />{" "}
					мы организовывали покатушки
				</p>
			</div>
		),
		textarea2: (
			<>
				Спустя какое-то время я&nbsp;подумал: «А почему бы не собирать
				велосипедистов Питера в&nbsp;собственном кафе?». Мне хотелось
				«причесать» велосипедное сообщество, чтобы&nbsp;оно&nbsp;немного
				оживилось&nbsp;&mdash; общее место для тусовки могло помочь. Люди могли
				заехать в&nbsp;велокафе, встретить кого-нибудь из знакомых
				велосипедистов и&nbsp;вместе либо весело провести время
				в&nbsp;заведении, либо прокатиться куда-нибудь.
				<br />
				<br />
				Так в 2020 году я&nbsp;решился на собственный бизнес. В кармане
				у&nbsp;меня было 200 тысяч рублей. Я&nbsp;подумал, что через месяц
				в&nbsp;любом случае их потрачу и&nbsp;даже не замечу, а&nbsp;если открою
				свой бизнес, то хотя бы буду знать, куда они ушли.{" "}
				<strong>
					Если не получится сделать коммерчески успешный
					проект&nbsp;&mdash;&nbsp;всё равно оставлю какой-то след:
				</strong>{" "}
				донесу мысль о&nbsp;том, что&nbsp;велосипедистам надо держаться вместе,
				и&nbsp;окажу влияние на велотусовку.
			</>
		),
		mediaContent2: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentTwo
				)}
			>
				<div
					className={classNames(
						styles.promptTypeOne,
						styles.promptIconOn,
						styles.promptIconOneMobile
					)}
				>
					<img
						className={styles.icon__one}
						src={PromptIconOne}
						alt="Иконка 1"
						loading="lazy"
					/>
					<p>
						В 2020 году я&nbsp;решился на&nbsp;бизнес с 200 тысячами рублей
						в&nbsp;кармане
					</p>
				</div>

				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwo}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwo}
							alt="интерьер кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					Мне хотелось «причесать» велосипедное сообщество, чтобы оно оживилось
				</p>
			</div>
		),
	},
	{
		textarea: (
			<>
				Скооперировался со своими друзьями, у&nbsp;которых была веломастерская,
				&mdash; в&nbsp;новом помещении мы решили открыть велокафе через стенку
				с&nbsp;веломастерской. Даже не&nbsp;переживали, что может не получиться,
				потому что у&nbsp;нас уже была наработанная аудитория. У&nbsp;ребят были
				постоянные клиенты, а&nbsp;меня хорошо знали в велосипедной тусовке.
				Раньше я&nbsp;никогда не сталкивался с&nbsp;общепитом, даже официантом
				не работал. Всю внутреннюю кухню узнавал с&nbsp;нуля. Развивать бизнес
				помогло то, что у&nbsp;меня уже были какие-то управленческие навыки,
				потому что я&nbsp;часто организовывал гонки и&nbsp;заезды.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={styles.icon__two}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>Велосипедистам надо держаться вместе</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent__container,
					styles.mediaContent__containerFirst
				)}
			>
				<div className={classNames(styles.mediaContent, styles.fancy__first)}>
					<Fancybox>
						<a
							data-fancybox="image2"
							href={MediaContentThree}
							className={styles.media}
						>
							<img
								src={MediaContentThree}
								alt="атмосфера в кофейне"
								loading="lazy"
							/>
						</a>
					</Fancybox>

					<p className={classNames(styles.desc, styles.descThree)}>
						У нас не специфическая атмосфера
					</p>
				</div>
				<div
					className={classNames(styles.mediaContent, styles.mediaContentFour)}
				>
					<Fancybox>
						<a
							data-fancybox="image3"
							href={MediaContentFour}
							className={styles.media}
						>
							<img
								src={MediaContentFour}
								alt="мастерская по ремонту велосипедов"
								loading="lazy"
							/>
						</a>
					</Fancybox>
					<p className={classNames(styles.desc, styles.descFour)}>
						Обычным людям тоже комфортно
					</p>
				</div>
			</div>
		),
		textarea2: (
			<>
				В 2022 году, спустя два года после открытия, к&nbsp;нам приходят не
				только велолюбители. Примерно 50% гостей&nbsp;&mdash; это те, кто зашёл
				во время прогулки с&nbsp;детьми, вышел выпить кофе из&nbsp;дома или
				офиса или забежал по пути с&nbsp;поликлиники, которая находится рядом.
				Поэтому я&nbsp;стараюсь, чтобы атмосфера была не сильно специфическая
				для велотусовки и&nbsp;обычным людям тоже было комфортно.
			</>
		),
	},
	{
		heading: "С ремонтом мне помогли знакомые, а кухню поставил крутой шеф",
		textarea: (
			<>
				Когда мы сняли помещение, я&nbsp;рассказал всем своим знакомым, что
				сошёл с&nbsp;ума и&nbsp;решил открыть велокафе. Попросил помощи, если
				вдруг кто-то умеет делать ремонт. Откликнулось очень много
				людей&nbsp;&mdash; они приходили и&nbsp;помогали не только в ремонте, но
				и&nbsp;в&nbsp;других делах. Например, один паренёк написал, что делает
				из бетона горшочки и плафончики, спросил, нужны ли они нам.
				Я&nbsp;ответил, что плафоны не нужны, а&nbsp;вот сделать барную стойку
				из бетона было бы круто. Он сделал. И&nbsp;так многие поддерживали, ведь
				велосипед&nbsp;&mdash; это хобби, и&nbsp;у&nbsp;всех есть какая-то
				профессиональная деятельность.
			</>
		),
	},
	{
		heading:
			"Ваня придумал крутые блюда, которые можно быстро собрать из заготовок",
		textarea: (
			<>
				Мне помогали не&nbsp;только в&nbsp;начале, но&nbsp;и&nbsp;после
				открытия. Как-то посетители рассказали, что у&nbsp;них есть знакомый,
				который собирается в&nbsp;велопутешествие, и&nbsp;попросили его
				проконсультировать. Я&nbsp;согласился. Он&nbsp;приехал в&nbsp;кафе,
				и&nbsp;мы&nbsp;с&nbsp;ним сразу сдружились. Это был Ваня Дубков
				из&nbsp;Москвы, крутой шеф-повар, который ставил меню
				в&nbsp;&laquo;Рихтере&raquo;. Ему понравилось моё велокафе,
				и&nbsp;он&nbsp;предложил поставить для нас семь блюд. Задача была
				в&nbsp;том, чтобы их&nbsp;мог готовить один человек за&nbsp;баром,
				потому что у&nbsp;меня нет отдельной кухни.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={styles.icon__three}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>
					На открытие потратили около 500 тысяч рублей и&nbsp;6&nbsp;месяцев
					времени
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentOne)}>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentFive}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFive}
							alt="подготовка к открытию кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFive)}>
					Никогда не сталкивался с&nbsp;общепитом&nbsp;&mdash; всю внутреннюю
					кухню узнавал с&nbsp;нуля
				</p>
			</div>
		),
		textarea2: (
			<>
				Ваня придумал крутые блюда, которые можно быстро собрать из заготовок
				и&nbsp;отдать гостю. Они кажутся простыми, но на самом деле продуманные
				и&nbsp;очень вкусные. Например, овсянка&nbsp;&mdash; в&nbsp;ней восемь
				ингредиентов: семена чиа, пюре, манго-пюре, арахисовая паста, что-то ещё
				и&nbsp;мята сверху. Сами мы бы такое не придумали.
			</>
		),
	},
	{
		heading:
			"Многие вещи я купил с рук. Пока открывал кофейню — стал магистром Авито",
		textarea: (
			<>
				Дизайн заведения я&nbsp;заранее не планировал: не знал, какая мне нужна
				мебель, посуда и&nbsp;прочие вещи. Всё делал интуитивно, например, ту же
				барную стойку придумал, ориентируясь на какой-то минимализм. Идеи
				появились в процессе. Например, вот история.{" "}
				<strong>
					Ещё до официального открытия, знакомые предложили провести у нас
					барахолку.
				</strong>{" "}
				Я&nbsp;решил: «Так, в&nbsp;кофейне должен быть графин с&nbsp;водой,
				чтобы попить её перед кофе и&nbsp;освежить рецепторы». На «голубом
				глазу» полетел в&nbsp;«ИКЕА», купил графин и стаканы.
				<br />
				<br />
				Мероприятие прошло, а&nbsp;я&nbsp;через неделю стою, смотрю на этот
				графин и&nbsp;думаю&nbsp;&mdash; блин, нормальный графин, но ничего
				крутого в нём нет. Решил зайти на «Авито», посмотреть, что&nbsp;там есть
				необычного, и&nbsp;нашёл классный советский гранёный графин.
				И&nbsp;пошло поехало. На&nbsp;Авито я&nbsp;купил много классных
				и&nbsp;недорогих вещей: диван за 300 рублей, которым пользуемся уже
				больше двух лет, кресло, винтажный фарфор «ЛФЗ», позолоченные ложечки,
				бильярдный стол, за которым ездил зимой за 70 километров, позолоченную
				кофемашину из&nbsp;Финляндии под фильтр.
			</>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContentFive,
					styles.mediaContent__rotateLeft
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentSix}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSix}
							alt="мебель для кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSix)}>
					На «Авито» я купил много классных и недорогих вещей
				</p>
			</div>
		),
		textarea2: (
			<>
				Много вещей принесли ребята из тусовки&nbsp;&mdash;{" "}
				<strong> у всех дома что-то завалялось</strong>, например журналы. Ты же
				не будешь ходить и&nbsp;предлагать людям: «У меня есть журнальчик,
				вот&nbsp;почитай». И&nbsp;сам его 10 раз тоже не будешь перечитывать.
				И&nbsp;ребята предлагали принести и&nbsp;оставить их в&nbsp;кафе.
				Я&nbsp;соглашался: «Прикольная штучка! Приноси, оставляй, кто-нибудь
				почитает». Это здорово сэкономило деньги и&nbsp;помогло сделать интерьер
				запоминающимся. К&nbsp;слову, на открытие мы потратили около 500 тысяч
				рублей и&nbsp;шесть месяцев времени. Сейчас я, возможно, не стал бы
				полгода просто так платить аренду. А&nbsp;лучше взял бы кредит
				на&nbsp;300&nbsp;тысяч рублей, нанял рабочих, быстро сделал ремонт
				и&nbsp;открыл кафе.
			</>
		),
	},
	{
		heading: "Если продумать план открытия заранее, будет проще",
		textarea: (
			<>
				Я&nbsp;думаю, что обычно люди подходят к&nbsp;открытию заведения со
				знанием дела: составляют план, считают деньги и&nbsp;всё такое.
				Я&nbsp;так не делал. У&nbsp;меня просто было огромное желание открыть
				велокафе для тусовки &mdash;&nbsp;решать проблемы я&nbsp;собирался на
				месте. Мне мама, серьёзный хирург, всегда так говорит:{" "}
				<strong>
					«Сначала надо в драку ввязаться, а&nbsp;дальше разберёмся».
				</strong>{" "}
				Сейчас я&nbsp;понимаю, что можно нафантазировать, а&nbsp;потом
				столкнуться с&nbsp;жёсткой реальностью.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={styles.icon__four}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>
					У меня не было стратегии &mdash;&nbsp;решать проблемы я&nbsp;собирался
					на месте
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFive)}>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentSeven}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSeven}
							alt="бизнес-план кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSeven)}>
					Можно нафантазировать, а потом столкнуться с&nbsp;жёсткой реальностью
				</p>
			</div>
		),
		textarea2: (
			<>
				Но хотя бы с&nbsp;каким-то планом мне было бы легче. Чтобы его
				составить, советую думать последовательно, потому что многое
				обнаруживается в&nbsp;процессе. Вот, например. Мы&nbsp;открываемся
				в&nbsp;9&nbsp;утра. Что должно быть в&nbsp;заведении в&nbsp;9&nbsp;утра:
				<ul className={styles.ul}>
					<li>Должен прийти сотрудник, и в кафе должно быть чисто.</li>
					<li>Чтобы было чисто, нужно, чтобы кто-то до 9 утра прибрался.</li>
					<li>
						Кто может прибраться? Сотрудник, который работал вчера, уборщица
						вечером после закрытия или сотрудник, который пришёл сегодня?
					</li>
					<li>
						Сотрудник пришёл, включил кофемашину. Она греется и&nbsp;мелет
						зерно.
					</li>
					<li>
						Откуда приехало зерно? Когда? Ведь оно всегда должно быть свежим.
					</li>
					<li>Приезжают замороженные десерты. Где их хранить?</li>
					<li>
						Сколько их нужно заказывать? Хватит места, чтобы хранить их
						в&nbsp;заморозке или&nbsp;нужно подвозить их раз в&nbsp;два дня?
					</li>
					<li>Успеют ли за это время их раскупить?</li>
				</ul>
				Ну и&nbsp;дальше по списку. Нужно просто прокручивать в голове, как
				проходит день, что&nbsp;происходит в&nbsp;каждый момент,
				и&nbsp;записывать все вопросы, которые возникают.
				<div
					className={classNames(
						styles.promptTypeOne,
						styles.promptIconFive,
						styles.promptIconFiveMobile
					)}
				>
					<img
						className={styles.icon__five}
						src={PromptIconFive}
						alt="Иконка 5"
						loading="lazy"
					/>
					<p>У нас около 60 чеков в день</p>
				</div>
			</>
		),
	},
	{
		heading: (
			<span className={styles.max_w}>
				Обязательно поставить систему автоматизации — она облегчит жизнь
				сотрудникам и владельцу
			</span>
		),
		textarea: (
			<>
				Я&nbsp;установил Quick Resto, чтобы упростить работу сотрудникам. Чтобы,
				когда гости заказывают пять позиций одновременно, ребята не считали на
				калькуляторе, какую сумму вбить в&nbsp;терминал, а&nbsp;могли быстро
				тыкнуть на «айпедике» и&nbsp;увидеть счёт. В&nbsp;кафе в&nbsp;смене
				работает один человек, ему нужно всё делать быстро и&nbsp;не занимать
				голову лишними расчётами.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={styles.icon__five}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>У нас около 60 чеков в день</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentFive
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentEight}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentEight}
							alt="система автоматизации для кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descEight)}>
					С помощью Quick Resto моя команда просто тыкает в «айпедике» и живёт
					легко
				</p>
			</div>
		),
		textarea2: (
			<>
				Кроме этого, система автоматизации Quick Resto полезна
				и&nbsp;мне&nbsp;&mdash; я&nbsp;периодически смотрю отчёты по&nbsp;самым
				популярным блюдам. Бывает, что самый популярный товар&nbsp;&mdash;
				с&nbsp;низкой маржинальностью, а&nbsp;с&nbsp;высокой никто
				не&nbsp;берёт.{" "}
				<strong>
					Так с&nbsp;помощью Quick Resto я&nbsp;ворочаю цифрами и&nbsp;что-то
					правлю.
				</strong>{" "}
				Нравится автоматический вычет со&nbsp;склада, когда сотрудник продаёт
				товар. Например, в&nbsp;капучино идёт 25&nbsp;грамм зерна и&nbsp;150
				миллилитров молока. Нажал &laquo;продать&raquo;, и&nbsp;эти
				25&nbsp;грамм и&nbsp;150 миллилитров сразу ушли со&nbsp;склада. Когда
				вместо 10&nbsp;кг стало 2&nbsp;кг, понимаешь, что пора заказывать новую
				партию. И&nbsp;не&nbsp;нужно бегать на&nbsp;склад и&nbsp;постоянно
				проверять. Ещё удобна функция &laquo;Списание&raquo;. Я&nbsp;или
				сотрудники пользуемся&nbsp;ею, когда сами пьём кофе или утром
				настраиваем помол и&nbsp;тратим на&nbsp;это зерно.
			</>
		),
	},
	{
		heading:
			"С небольшой кофейни можно получить больше, чем средняя зарплата в найме",
		textarea: (
			<>
				Заработок зависит от концепции места, его расположения, аудитории,
				оборудования. Например, кофейни, которые находятся недалеко друг от
				друга в&nbsp;центре Питера, могут приносить очень разный доход.{" "}
				<strong>В&nbsp;«Кооператив “Вираж”» бывают странные вещи</strong>.
				Два&nbsp;одинаковых летних дня, оба&nbsp;&mdash; вторники, а&nbsp;кассы
				отличаются в&nbsp;два раза. Непонятно, почему. Я&nbsp;думал, может,
				у&nbsp;меня какая-то такая особенность. Но нет, везде так. Это как цена
				на&nbsp;нефть&nbsp;&mdash; откуда она взялась, кто на неё влияет?
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={styles.icon__six}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>Наш средний чек&nbsp;&mdash; 250–300 рублей</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFive)}>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentNine}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentNine}
							alt="работа бариста в кофейне"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descNine)}>
					Два одинаковых летних дня, оба — вторники, а&nbsp;кассы отличаются
					в&nbsp;два раза
				</p>
			</div>
		),
		textarea2: (
			<>
				Думаю, что в небольшой кофейне можно зарабатывать больше, чем получают
				на&nbsp;среднестатистической работе в найме. Например, у&nbsp;меня
				50–60&nbsp;чеков в&nbsp;день. Средний чек 250–300 рублей.{" "}
				<strong>
					12–18&nbsp;тысяч рублей выручки в&nbsp;день&nbsp;&mdash; для меня
					хороший показатель
				</strong>
				. Но тут нужно учесть, что у&nbsp;меня небольшие затраты. Аренда
				70&nbsp;тысяч рублей, но&nbsp;мы&nbsp;с&nbsp;веломастерской делим её
				пополам, то есть я&nbsp;плачу только 35 тысяч. Конечно, есть ещё
				коммуналка, охрана, оплата Quick Resto и&nbsp;прочие расходы, но всё
				равно выходит не так много, и&nbsp;прибыль кафе меня полностью
				устраивает.
			</>
		),
	},
]
