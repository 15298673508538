import React, { useEffect, useState } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"

import { requestForAutoOpenRestFaster } from "../../apiRequests/callToAction"
import DefaultLayout from "../../layout/default"

import HeadingTextareaAndPrompt from "../../components/_V3/SecretPages/HeadingTextareaAndPrompt"
import MainBanner from "../../components/_V3/SecretPages/MainBanner"

import SocialNetworkBanner from "../../components/_V3/SecretPages/SocialNetworkBanner"

import ConsultationForm from "../../components/_V3/SecretPages/ConsultationForm"

import FooterCompact from "../../components/_V2/FooterCompact"

import SendPulseModal from "../../components/_V2/SendPulseModal"

import { mainBanner } from "../../pages-data/_V3/secret/virazh/MainBanner/data"
import { headingTextareaAndPrompt } from "../../pages-data/_V3/secret/virazh/HeadingTextareaAndPrompt/data"
import { socialNetworkBanner } from "../../pages-data/_V3/secret/SocialNetworkBanner/data"
import { consultationForm } from "../../components/_V3/SecretPages/ConsultationForm/data"

import MediaMainBanner from "../../pages-data/_V3/secret/virazh/MainBanner/assets/MediaMainBanner.webp"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/secret.module.scss"

export default function Virazh() {
	const title =
		"Секретный ингредиент велокафе «Вираж»: как собрать велосипедистов в собственном кафе?"
	const description =
		"Основная профессия Максима Кравцова — звукорежиссёр. А велоспорт — одно из главных увлечений. Максим рассказывает, как хобби переросло в своё велокафе «Кооператив “Вираж”», за счёт чего удалось сэкономить при открытии и сколько зарабатывает с заведения сейчас."
	const url = "https://quickresto.ru/secret/virazh/"

	const metaTags = [
		{
			name: "description",
			content: description,
		},
		{
			property: "og:url",
			content: url,
		},
		{
			property: "og:title",
			content: title,
		},
		{
			property: "og:description",
			content: description,
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content: MediaMainBanner,
		},
	]

	// Проверка страницы на скролл до 50% для показа попапа о подписке
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	let isHalfScrolled = false
	const handleScroll = () => {
		const pageHeight = document.body.scrollHeight
		const pageHalfHeight = pageHeight / 2

		// Если скролл больше половины страницы то показываем попап
		if (window.scrollY >= pageHalfHeight && isHalfScrolled === false) {
			setIsModalOpen(true)

			isHalfScrolled = true
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", debounce(handleScroll, 500))

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	return (
		<DefaultLayout
			title={title}
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.bg
			)}
			linkCanonical={url}
			isFooterEnable={false}
		>
			<MainBanner
				data={mainBanner}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				mobilaHiddenClassName={classNames(pageStyles.mobileHidden)}
				data={headingTextareaAndPrompt[0]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[1]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[2]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[3]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				data={headingTextareaAndPrompt[4]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				rowClassName={classNames(pageStyles.mobileReverse)}
				data={headingTextareaAndPrompt[5]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<SocialNetworkBanner
				data={socialNetworkBanner}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<HeadingTextareaAndPrompt
				mobilaHiddenClassName={classNames(pageStyles.mobileHidden)}
				data={headingTextareaAndPrompt[6]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>
			<HeadingTextareaAndPrompt
				rowClassName={classNames(pageStyles.mobileReverse)}
				data={headingTextareaAndPrompt[7]}
				className={classNames(pageStyles.pageSection, pageStyles.bigMargin)}
			/>

			<ConsultationForm
				data={consultationForm}
				className={classNames(pageStyles.pageSection)}
				request={requestForAutoOpenRestFaster}
			/>

			<FooterCompact className={styles.footer} />

			<SendPulseModal isOpen={isModalOpen} onClose={handleModalStatus} />
		</DefaultLayout>
	)
}
