import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MediaMainBanner from "./assets/MediaMainBanner.webp"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<picture className={styles.mainBanner__picture}>
				<source srcSet={MediaMainBanner} media="(min-width: 1024px)" />
				<img
					className={styles.mainBanner__image}
					src={MediaMainBannerMobile}
					alt="кофейня Вираж"
					loading="lazy"
				/>
			</picture>
		),
		desktopBanner: (
			<img src={DesktopMediaMainBanner} alt="кофейня Вираж" loading="lazy" />
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<div className={styles.leadContainer}>
				Бизнес из&nbsp;хобби.&nbsp;{" "}
				<span className={styles.heading}>
					Кейс&nbsp;<span className={styles.quote}>«Вираж»</span>
				</span>
			</div>
		),
	},
	heading: "Как без опыта превратить хобби в работающий бизнес",
	textarea: (
		// <p className={styles.leadParagraph}>
		<p className={styles.letter_spacing}>
			Основная профессия Максима Кравцова&nbsp;&mdash; звукорежиссёр.
			А&nbsp;велоспорт&nbsp;&mdash; одно из&nbsp;главных увлечений. Максим
			рассказывает, как хобби переросло в&nbsp;своё велокафе &laquo;Кооператив
			&bdquo;Вираж&ldquo;&raquo;, за&nbsp;счёт чего удалось сэкономить при
			открытии и&nbsp;сколько зарабатывает с&nbsp;заведения сейчас.
		</p>
	),
	video: {
		src: "https://www.youtube.com/embed/sT4wUKS8_mc",
		textarea: <p className={styles.textarea}>Крути педали к мечте</p>,
	},
}
